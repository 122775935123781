
import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/tapes_without_backing.png"

const materials = [
  { product: '3M 8132LE', description: 'Erinomainen  pitovoima useimille pinnoille', thickness: '50 µm', glue: 'Akryyli 300LSE', temprange: '149-93°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000040?locale=en-US' },
  { product: '3M 8153LE', description: 'Erinomainen  pitovoima useimille pinnoille', thickness: '90 µm', glue: 'Akryyli 300LSE', temprange: '149-93°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000041?locale=en-US' },
  { product: '3M 9472LE', description: 'Erinomainen  pitovoima useimille pinnoille', thickness: '130 µm', glue: 'Akryyli 300LSE', temprange: '204-149°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000044?locale=en-US' },
  { product: '3M 467MP', description: 'Yleisteippi sileille pinnoille, hyvä leikkauslujuus', thickness: '50 µm', glue: 'Akryyli 200MP', temprange: '204-149°C', application: 'Metallit ja korkean pinta-energian muovit', datasheet: 'https://3m.citrination.com/pif/000024?locale=en-US' },
  { product: '3M 468MP', description: 'Yleisteippi sileille pinnoille, hyvä leikkauslujuus', thickness: '130 µm', glue: 'Akryyli 200MP', temprange: '204-149°C', application: 'Metallit ja korkean pinta-energian muovit', datasheet: 'https://3m.citrination.com/pif/000025?locale=en-US' },
  { product: '3M 9626', description: 'Todella nopea tartunta, korkea pitovoima ohueksi teipiksi', thickness: '50 µm', glue: 'Akryyli 360', temprange: '350-200°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000376?locale=en-US' },
  { product: '3M F9460PC', description: 'Erinomainen pitkänajan pitovoima, korvaa niittit ja pistehitsit', thickness: '50 µm', glue: 'Akryyli 100MP', temprange: '260-149°C', application: 'Metallit ja korkean pinta-energian muovit', datasheet: 'https://3m.citrination.com/pif/000021?locale=en-US' },
  { product: '3M F9469PC', description: 'Erinomainen pitkänajan pitovoima, korvaa niittit ja pistehitsit', thickness: '130 µm', glue: 'Akryyli 100MP', temprange: '204-149°C', application: 'Metallit ja korkean pinta-energian muovit', datasheet: 'https://3m.citrination.com/pif/000022?locale=en-US' },
  { product: '3M F9473PC', description: 'Erinomainen pitkänajan pitovoima, korvaa niittit ja pistehitsit', thickness: '260 µm', glue: 'Akryyli 100MP', temprange: '204-149°C', application: 'Metallit ja korkean pinta-energian muovit', datasheet: 'https://3m.citrination.com/pif/000023?locale=en-US' },
];

export function TapesWithoutPackingPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Selkäaineettomat teipit</h1>
            <p className="article-text">
            Selkäaineettomassa teipissä liima-aineen molemmilla puolilla on irroituskalvo. 
            Asennettaessa selkäaineetonta teippä, se irroitetaan ensin toiselta irroituskalvolta, 
            painetaan kiinni haluttuun kohtaan ja lopuksi toinenkin irroituskalvo poistetaan. Selkäaineeton 
            teippi ei pysy muodossaan ilman irroituskalvoja.
            
            </p>
            <br></br>
            <br></br>
            <p className="article-text">
              Selkäaineettomia teippejä käytetään esimerkiksi elektroniikka- ja laiteteollisuuden komponenteissa,
              joissa teippi voidaan kiinnittää jäykkään rakenteeseen. Esimerkiksi kylttien ja kalvonäppäimistöjen kiinnitys
              sekä levymäisten komponenttien liimaus.
            </p>
            <br></br>
            <div>
              <br></br>
              <br></br>
                <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function MaterialTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Liima</th>
          <th className="table-header">Materiaalit</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {materials.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell center">{material.thickness}</th>
            <th className="table-cell center">{material.glue}</th>
            <th className="table-cell left">{material.application}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}