import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/screen_cover_1.png"
const ProductImage = "/images/screen_cover_1.png"

export function CoverMaterialsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Suojakalvot</h1>
            <p className="article-text justify">
              Muotoonleikatut tiivisteet ovat helppo ja kustannustehokas ratkaisu elektroniikkalaitteiden koteloiden tiivistämiseen sekä iskunvaimennukseen.
              Varastostamme löytyy kattava valikoima erilaisia tiivistemateriaaleja, joilla onnistuu niin vaativat IP-luokan tiivistykset,
              kuin komponenttien iskunvaimennus kokoonpanoissa. Varastomateriaaliemme lisäksi pystymme hankkimaan erikoisemmatkin materiaalit projektiasi varten.
              <br></br>
              <br></br>
              Muotoonleikkauksen lisäksi pystymme laminoimalla yhdistämään tiivistemateriaaleihin liimapinnan helppoa asennusta varten. Autamme myös
              mielellämme laitteesi tiivistyksen suunnittelussa.
            </p>
            <br></br>
            <h1 className="std-subheader">Polyeteeni (PE)</h1>
            <p className="article-text justify">
              <br></br>
              Tarjoamamme muotoonleikatut akustiset paineentasauskalvot ovat helppo ja kustannustehokas ratkaisu herkän elektroniikan, kuten puhelimien, tablettien ja nappikuulokkeiden kaiutinelementtien suojaaminen pölyltä ja vedeltä, kauttimen äänenlaatua heikentämättä.
              <br></br>
              <br></br>
              Tarjoamme alan standardikokojen lisäksi asiakastarpeen räätälöityjä suojaelementtejä, jolloin tuotteen muoto, materiaalivalinnat sekä rakenne voidaan valita käyttökohteen mukaan. Tälla tavalla elemeneilla saavutetaan täydellinen tasapaino suojauksen ja äänenlaadun välillä, mahdollistaen samalla lopputuotteen
              tuotantoa ajatellen optimaalisen rakenteen ja asennuspiirteien toteutamisen.
              <br></br>
              <br></br>
              <p>Vakiorakenteessamme on akustinen nanoverkko joka tiivistää läpiviennin vedeltä aina IPX8 luokkaan asti, kuitenkaan heikentämättä äänenlaatua. Kiinnittämistä varten rakenteessa on liimapinta. Pystymme toimittamaan vakiorakenteellamme olevia suojaelementtejä nopealla aikataululla haluttuun muotoon leikattuna.</p>
              <br></br>
            </p>
            <br></br>
            <h1 className="std-subheader">Akryyli</h1>
            <br></br>
            <br></br>
            <p className="article-text">
              Vakiorakenteessamme on akustinen nanoverkko joka tiivistää läpiviennin vedeltä aina IPX8 luokkaan asti, kuitenkaan heikentämättä äänenlaatua. 
              Kiinnittämistä varten rakenteessa on liimapinta. Pystymme toimittamaan vakiorakenteellamme olevia suojaelementtejä nopealla aikataululla haluttuun 
              muotoon leikattuna.
            </p>
            <br></br>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <img src={ProductImage} alt="Image" className="article-image" />
                <br></br>
              </div>
            </div>
            <br></br>
            <br></br>
            <div>
              <br></br>
              <br></br>
              <p className="article-text">
                Tuemme mielellämme teitä aina suunnitteluvaiheen materiaalivalinnoista, protosarjojen valmistukseen ja sarjatuotantoon asti.
              </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}
