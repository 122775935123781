import clock from "../icons/clock.png";
import { CallToAction } from "../components/common-components";

//const bannerImage = "/images/poron_test.png"
const bannerImage = "/images/Kuva_2.jpg"

export function ImageBanner() {

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function Article() {
  return (
    <div>
      <div>
        <h1 className="std-header">Our Services</h1>
      </div>
      <div className="block-container">
        <p className="article-text">
        At Prototape, we serve all of your component assembly, sealing, and heat transfer needs. We provide both large and small batch orders 
        with flexibility and speed. In addition to manufacturing, we offer consulting services to support your decisions in achieving the best end product.
        </p>
        <br></br>
        <h1 className="std-subheader">Prototyping:</h1>
        <p className="article-text">
        With our quick prototyping capability, we aim to bring flexibility and speed to our customers' product development. We maintain a wide range of materials, 
        allowing us to provide materials that are suitable for general customer needs, cut from our own stock materials. In addition to fast delivery, 
        our material stock enables the cost-effective production of even small prototype batches, making it easy to find the right design or material properties by testing multiple materials.
        </p>
        <br></br>
        <h1 className="std-subheader">Material Selection:</h1>
        <p className="article-text">
        Our operations are based on a broad collaboration with various material suppliers, which allows us to recommend the best material for our customers' needs 
        from the selection of different manufacturers. By utilizing this knowledge of materials, our customers receive the best features, cost and 
        availability for their components. In addition to material selection, we help our customers fully utilize custom-cut products, by 
        providing assistance with product design, sizing, and fitting into our customers' manufacturing process.
        </p>
        <br></br>
        <h1 className="std-subheader">Mass Production:</h1>
        <p className="article-text">
        In series production, we utilize our own production and a wide network of partners, allowing us to acquire large batches cost-effectively 
        and of high quality from the supplier that best meets each need.
        </p>
        <br></br>
        <h1 className="std-subheader">Procurement Consulting:</h1>
        <p className="article-text">
        We have a good knowledge of domestic, European, and Asian players in the field. We offer our customers a ready-made package of possible 
        suppliers for the components of the product, offered prices, and supplier-collected manufacturing and cost-saving improvement suggestions. 
        In this way, we help our customers to streamline product development and procurement resources, speed up product launch, and ensure 
        product competitiveness through manufacturability, cost-effectiveness, and good availability.
        </p>
        <br></br>
        <h1 className="std-subheader">Engineering & Technical Analysis:</h1>
        <p className="article-text">
        With the necessary tools and expertise at our disposal, we are able to design both individual components and complex structures, as well as 
        conduct comprehensive analyses. Our toolkit includes state-of-the-art 3D/2D CAD programs and advanced tools for strength, heat transfer, 
        and flow calculations utilizing FEA and CFD methods.  
        </p>
      </div>
    </div>
  )
}


export function ServicesPage() {
  return (
    <div className="block-container">
      <ImageBanner></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <Article></Article>
            <br></br>
            <CallToAction></CallToAction>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

