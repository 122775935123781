import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/acustic_vent_1.jpeg"
const ProductImage = "/images/acustic_vents_1.png"

export function AcusticMaterialsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Pressure Vents & Acustic Membranes</h1>
            <br></br>
            <p className="article-text justify">
              Our custom die-cut acoustic pressure equalization membranes are an easy and cost-effective solution for protecting sensitive electronics,
              such as phones, tablets, and earbud speakers from dust and water, without compromising speaker sound quality.              <br></br>
              <br></br>
              We offer standard sizes as well as custom-fit protective elements tailored to customer needs, allowing for the perfect
              balance between protection and sound quality, while also considering optimal structure and installation features for the final product.
              <br></br>
              <br></br>
              Our standard structure features an acoustic nano-network that seals the passage from water up to IPX8 class,
              without compromising sound quality. The structure also includes a glue surface for attachment. We can quickly provide standard structures in the desired die-cut shape.              <br></br>
              <br></br>
            </p>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <img src={ProductImage} alt="Image" className="article-image" />
                <br></br>
              </div>
            </div>
            <br></br>
            <br></br>
            <div>
              <br></br>
              <br></br>
              <p className="article-text">
              Let us support you every step of the way, from material selection during the design phase to prototype production 
              and mass manufacturing. Contact us today to learn more about how we can help bring your project to life.
              </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}
