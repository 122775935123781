import { CallToAction } from "../components/common-components";

//const bannerImage = "/images/poron_test.png"
const bannerImage = "/images/Kuva_3.jpg"


const prices = [
  { product: '4701-30', description: 'Todella pehmeä ja helposti kokoonpuristuva tiivistemateriaali tiivistykseen, raon täyttämiseen, ja joustoon', thickness: '0.8mm-12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-30-very-soft.pdf' },
  { product: '4701-40', description: 'Pehmeä ja helposti kokoonpuristuva tiivistemateriaali tiivistykseen, raon täyttämiseen, ja joustoon', thickness: '0.8mm-12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-40-soft.pdf' },
  { product: '4701-50', description: 'Jäykähkö materiaali tiivistykseen ja iskunvaimennukseen', thickness: '0.8mm-12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-50-firm.pdf' },
  { product: '4701-60', description: 'Jäykkä materiaali tiivistykseen sekä törmäys ja iskunvaimennukseen', thickness: '0.8mm-6.35mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-60-very-firm.pdf' },
  { product: '4790-92', description: 'Tosi pehmeä ja hitaasti palautuva (rebound) matalan energian iskunvaimennuskäyttö', thickness: '0.5mm-12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4790-92-extra-soft---slow-rebound-supported-data-sheet.pdf' },
  { product: '4701-37', description: 'Aquapro vedenkestävä materiaali vaativiin IP luokan tiivistyksiin / alipainekäyttöön', thickness: '0.15mm-12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-aquapro-datasheet.pdf' },
];



export function OrderPage() {

  return (
    <div>
      <ImageBanner></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Tilaaminen</h1>
            <p className="article-text">
              Taataksemme sujuvan palvelukokemuksen ja nopean toimituksen, olemme koonneet tälle sivulle yksinkertaiset ohjeet tuotteiden tilaukseen.
              Sisällyttämällä nämä tiedot tarjouspyyntöönne, saatte meiltä suoraan tarjouksen tuotteista ilman ylimääräisiä kysymyksiä.
            </p>
            <br></br>
            <h1 className="std-subheader">Tarvittavat tiedot:</h1>
            <p className="article-text">
              1: Leikkausradat kerroksille DXF tai DWG formaatissa 1:1 mittakaavassa. <br></br>
              2: Mahdollisuuksien mukaan erottele läpileikattavat ja tiettyyn kerrokseen leikattavat radat eri viivanleveydellä tai värillä. <br></br>
              3: Listaus käytettävistä materiaaleista kerroksittain. <br></br> 
              4: Ilmoita muut tuotteessa tai toimituksessa huomioitavat kohdat. <br></br>
              <br></br>
              Autamme mielellämme leikattavien tuotteiden suunnittelussa.
            </p>
            <br></br>
            <h1 className="std-subheader">Toimitus:</h1>
            <p className="article-text">
              Posti Express: Normaali toimitustapamme on Postin Express-kuljetus, joka toimittaa lähetyksen pääsääntöisesti seuraavaksi päiväksi.
            </p>
            <br></br>
            <p>
              Kuriiritoimitus: Kiireellisissä tapauksissa hoidamme toimituksen kuriiripalveluja käyttäen, jolloin lähetys voidaan toimittaa jopa samana päivänä.
            </p>
            <br></br>
            <p>
              Nouto: Sovittaessa tuotteet on mahdollista noutaa tuotantotilaltamme välittömästi tuotteiden valmistuttua.
            </p>
            <br></br>
            <h1 className="std-subheader">Hinnoittelu:</h1>
            <p className="article-text">
              Hinnoitteluun vaikuttaa valitut materiaalit, materiaalin kulutus, leikattavien kappaleiden määrä, mahdolliset laminoitavat kerrokset, sekä toimitustapa.
            </p>
            {/*
            <br></br>
            <PriceTable></PriceTable>
            <br></br>
            */}
              <CallToAction></CallToAction>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

export function Article() {

  return (
    <div>
    </div>
  )
}

export function ImageBanner() {
  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function PriceTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
        </tr>

        {prices.map((price) => (
          <tr className="table-row">
            <th className="table-cell left">{price.product}</th>
            <th className="table-cell left">{price.description}</th>
            <th className="table-cell left">{price.thickness}</th>
          </tr>
        ))}
      </table>
    </div>
  )
}