import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/Pic_5.png"

const materials = [
  { product: '3M 4926', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '0.4 mm', color: 'Harmaa', temprange: '149-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000059?locale=en-US' },
  { product: '3M 4929', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '0.6 mm', color: 'Musta', temprange: '149-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000333?locale=en-US' },
  { product: '3M 4941', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '1.1 mm', color: 'Harmaa', temprange: '149-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000062?locale=en-US' },
  { product: '3M 4949', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '1.1 mm', color: 'Musta', temprange: '149-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000336?locale=en-US' },
  { product: '3M RP16', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '0.4 mm', color: 'Harmaa', temprange: '121-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000090?locale=en-US' },
  { product: '3M RP25', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '0.6 mm', color: 'Harmaa', temprange: '121-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000092?locale=en-US' },
  { product: '3M RP32', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '0.8 mm', color: 'Harmaa', temprange: '121-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000094?locale=en-US' },
  { product: '3M RP45', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '1.1 mm', color: 'Harmaa', temprange: '121-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000096?locale=en-US' },
  { product: '3M RP62', description: 'Hyvä pitovoima, tärinää vaimentava, kosteutta kestävä', thickness: '1.6 mm', color: 'Harmaa', temprange: '121-93°C', application: 'Elektroniikka- , kone-, ajoneuvoteollisuus', datasheet: 'https://3m.citrination.com/pif/000098?locale=en-US' },
];

export function VhbMaterialPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Kaksipuoleiset vaahtoteipit</h1>
            <p className="article-text">
            Kaksipuolisten vaahtoteippien vaahtomaisen selkäaineen (esim. akryyli, uretaani, polyeteeni) kummallekin 
            puolelle on laminoitu kerros liima-ainetta. Näin ollen teipin molemmilla puolilla voi olla erilaiset ominaisuudet. 
            Teippien vaahtomainen rakenne täyttää pinnassa mahdollisesti olevat epätasaisuudet ja raot sekä mahdollistaa 
            ainutlaatuiset elastomeeriset ominaisuudet.
            </p>
            <br></br>
            <br></br>
            <p className="article-text">
            Kaksipuoleisia vaahtoteippejä käytetään sovelluksissa, jossa teipiltä vaaditaan pitkäikäisyyttä ja erittäin suurta 
            pitovoimaa. Sen elastinen rakenne toimii tärinän- ja äänenvaimentimena. Vaahtoteippejä käytetään tyypillisesti korvaamaan
            kiinikkeitä ja liimoja eri teollisuudenaloilla, kuten ajoneuvo, elektroniikka, kodinkone teollisuudessa. Lisätietoa
            vaahtoteippien käyttökohteista on saatavilla <a target="_blank" href="https://www.3m.com/3M/en_US/vhb-tapes-us/">täältä.</a>
            </p>
            <div>
              <br></br>
              <p className="article-text">
                Pidämme yleisimmin käytettyjä materiaaleja aina varastossa, erikoisemmissa tarpeissa
                pystymme hankkimaan nopeasti tarvitsemasi materiaalin.
              </p>
              <br></br>
                <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function MaterialTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Väri</th>
          <th className="table-header">Käyttökohteet</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {materials.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell left">{material.thickness}</th>
            <th className="table-cell left">{material.color}</th>
            <th className="table-cell left">{material.application}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}