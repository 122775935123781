import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/Kuva_2.jpg"


const image1 = "/images/EPDM_samples.png"
const image2 = "/images/eva_samples.png"
const image3 = "/images/silicone_seal.png"

const materials_1 = [
  { product: '4701-30', description: 'Erittäin pehmeä ja helposti kokoonpuristuva tiivistemateriaali tiivistykseen, välien täyttämiseen, ja pehmusteeksi', thickness: '0.8 - 12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-30-very-soft.pdf' },
  { product: '4701-40', description: 'Pehmeä ja helposti kokoonpuristuva tiivistemateriaali tiivistykseen, välien täyttämiseen, ja pehmusteeksi', thickness: '0.8 - 12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-40-soft.pdf' },
  { product: '4701-50', description: 'Jäykähkö tiivistemateriaali tiivistykseen ja iskunvaimennukseen', thickness: '0.8 - 12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-50-firm.pdf' },
  { product: '4701-60', description: 'Jäykkä tiivistemateriaali tiivistykseen ja iskunvaimennukseen', thickness: '0.8 - 6.35mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4701-60-very-firm.pdf' },
  { product: '4790-92', description: 'Erittäin pehmeä ja hitaasti palautuva tiivistemateriaali, myös matalan energian iskunvaimennuskäyttöön', thickness: '0.5 - 12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-4790-92-extra-soft---slow-rebound-supported-data-sheet.pdf' },
  { product: '4701-41', description: 'Pehmeä AquaPro™ tiivistemateriaali vaativiin IP-luokiteltuihin tiivistyksiin / alipainekäyttöön', thickness: '0.15 - 12.7mm', datasheet: 'https://rogerscorp.com/-/media/project/rogerscorp/documents/elastomeric-material-solutions/poron/english/data-sheets/poron-aquapro-4701-41-soft---enhanced-sealability.pdf' },
];

const materials_2 = [
  { product: 'LS-10', description: 'Pehmeä ja helposti kokoonpuristuva tiivistemateriaali tiivistykseen, välien täyttämiseen, ja pehmusteeksi', thickness: '0.8 - 12.7mm', datasheet: 'https://earglobal.com/media/7440/mss8.pdf' },
  { product: 'LS-15', description: 'Pehmeähkö ja helposti kokoonpuristuva tiivistemateriaali tiivistykseen, välien täyttämiseen, ja pehmusteeksi', thickness: '0.8 - 12.7mm', datasheet: 'https://earglobal.com/media/7440/mss8.pdf' },
  { product: 'LS-20', description: 'Jäykähkö materiaali tiivistykseen ja iskunvaimennukseen', thickness: '0.8 - 12.7mm', datasheet: 'https://earglobal.com/media/7440/mss8.pdf' },
  { product: 'LS-25', description: 'Jäykkä materiaali tiivistykseen ja iskunvaimennukseen', thickness: '0.8 - 12.7mm', datasheet: 'https://earglobal.com/media/7440/mss8.pdf' },
];

export function GasketMaterialsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Sealing & Shock Absorbtion</h1>
            <p className="article-text justify">
            Die cut gaskets are the perfect solution for sealing electronic device enclosures and providing shock absorption. With our 
            vast inventory of high-quality gasket materials, we can help you achieve demanding IP-class seals, as well as component shock 
            absorption in assemblies. Whether you're working on a new product development or looking to improve an existing one, our die cut gaskets will help you to achieve your goals.
              <br></br>
              <br></br>
              Our gaskets can be made up of multiple layers, such as adhesive surface, gasket material, and alignment features on the installation film, 
              which ensures the perfect fit, durability and protection of your electronic devices. With our vast selection of materials and expert team, 
              we can help you to find the perfect solution for your specific needs.
              <br></br>
              </p>
            <br></br>
            <div>
              <h1 className="std-subheader">Microcellular Polyurethane</h1>
              <br></br>
              <p className="article-text">
              Microcellular polyurethane is a type of polyurethane that is 
              characterized by its small, closed-cell structure. These gaskets are known for their exceptional durability, 
              low compression set, shock absoption capability and excellent sealing properties. They are suitable for use in a wide range of applications 
              such as industrial, electronic equipment, and appliances. They have high compression set resistance, 
              which means they can retain their shape after being compressed multiple times. They have excellent sealing properties, 
              which makes them suitable for use in applications that require a tight seal. They have good chemical resistance and 
              can withstand exposure to a wide range of chemicals and fluids, making them suitable for use in harsh chemical environments. 
              They can also withstand high temperatures. Microcellular polyurethane gaskets can be customized to meet specific requirements and 
              are available in a wide range of sizes and shapes.
                </p>
              <br></br>
              <div className="flex-container">
                <img src={image1} alt="Image" className="article-image m" />
              </div>
            </div>
            <div>
              <h1 className="std-subheader">EPDM-Rubber</h1>
            </div>
            <br></br>
            <p className="article-text">
            EPDM rubber gaskets are precision-cut seals made from a type of synthetic rubber known for its excellent 
            resistance to heat, ozone and weathering. These gaskets are suitable for use in a wide range of applications 
            such as automotive, industrial and electronic equipment, and appliances. They have good temperature resistance, 
            which means they can withstand exposure to high temperatures without losing their shape or effectiveness. They 
            have excellent weathering resistance, which makes them suitable for use in outdoor applications where they will be 
            exposed to sunlight, rain and other weather conditions. They have good chemical resistance and can withstand 
            exposure to a wide range of chemicals and fluids, making them suitable for use in harsh chemical environments. 
            They have good electrical insulation properties, making them suitable for use in electrical applications. 
            EPDM rubber gaskets can be customized to meet specific requirements and are available in a wide range of sizes and shapes.
              </p>
            <br></br>
            <div>
              <div className="flex-container">
                <img src={image2} alt="Image" className="article-image m" />
              </div>
            </div>
            <div>
              <h1 className="std-subheader">Silicone</h1>
              <br></br>
              <p className="article-text">
              Silicone seals are known for their exceptional durability and flexibility, making them the 
              ideal solution for various applications. They have a wide range of benefits including high temperature resistance, chemical 
              resistance, flexibility, durability and cost-effectiveness. These gaskets can withstand high temperatures and are suitable for 
              use in high-temperature applications such as automotive and industrial equipment. They are also resistant to many chemicals, 
              making them suitable for use in harsh chemical environments. The high level of flexibility of these gaskets allows them to 
              conform to irregular surfaces, making them ideal for use in tight spaces and irregularly shaped equipment. They are 
              also highly durable and can withstand repeated cycles of compression and relaxation without losing their effectiveness. 
              Additionally, die-cut silicone gaskets are cost-effective as they are easy to produce with minimal material waste. Some 
              applications where die-cut silicone gaskets are commonly used include: lighting, medical, industrial and electronic 
              equipment, food and beverage processing equipment, and appliances.
              </p>
              <br></br>
              <div className="flex-container">
                <img src={image3} alt="Image" className="article-image m" />
              </div>
            </div>
            <br></br>
            <div>
              <br></br>
              <br></br>
              <p className="article-text justify">
              With our wide range of capabilities and materials, we can help you find the perfect gasket solution for your application. 
              Contact us today to learn more about how our die cut gaskets can improve your equipment's performance and reduce your costs.
            </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function MaterialTable_1(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>

        {materials_1.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell center">{material.thickness}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function MaterialTable_2(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>

        {materials_2.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell center">{material.thickness}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}