import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/acustic_vent_1.jpeg"
const ProductImage_1 = "/images/Pressure vents.png"
const ProductImage_2 = "/images/acustic_vents_1.png"
const DataSheetPath = "/datasheets/test_sheet.pdf"

const pressure_vent_products = [
  { size: 'D3.8xd1.6', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D4.2xd2.0', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D5.0x2.0', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D6.0xd3.0', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D10.2xd5.5', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
];

const acustic_vent_products = [
  { size: 'D3xd1.4', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D3.2xd1.6', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D3.6xd2.0', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D4.2xd1.6', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D5xd2.4', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D6x3', color: 'Musta tai valkoinen', ipclass: 'IP67', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
];

const acustic_dust_vent_products = [
  { size: 'D8.0xd3.0', color: 'Musta tai valkoinen', ipclass: 'IP65', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D9.4xd5.0', color: 'Musta tai valkoinen', ipclass: 'IP65', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D12.7xd6.4', color: 'Musta tai valkoinen', ipclass: 'IP65', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D19.0xd9.0', color: 'Musta tai valkoinen', ipclass: 'IP65', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D25.4xd12.7', color: 'Musta tai valkoinen', ipclass: 'IP65', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'D29.0xd20.0', color: 'Musta tai valkoinen', ipclass: 'IP65', temprange: '-30-120°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
];

const plastic_vent_products = [
  { size: 'M5x0.8', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M6x1', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M8x1.25', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M10x1.5', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M12x1.5', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M16x1.5', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M24x1.5', material: 'PP, PC, PC+ABS', ipclass: 'IP67', temprange: '-40-125°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
];

const metal_vent_products = [
  { size: 'M4x0.7', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M5x0.8', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M6x1', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M8x1.25', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M10x1.5', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M12x1.5', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M16x1.5', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M20x1.5', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
  { size: 'M40x1.5', material: 'Al, Cu, 304, 316', ipclass: 'IP67 & IP68', temprange: '-40-150°C', time: '2-4 viikkoa', datasheet: DataSheetPath },
];




export function VentsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Standard Pressure Vents & Membranes</h1>
            <p className="article-text justify">
            Our extensive range of standard pressure vents and acoustic membranes has been designed to meet the needs of rugged electronic devices. 
            Our product range includes a wide variety of hard and soft pressure vents in all the common sizes, as well as custom-made options to suit your specific needs.
            </p>
            <br></br>
            <p className="article-text justify">
            Pressure equalization vents protect device seals from stress caused by pressure changes and premature failures. These vents allow 
            for free flow of air between the enclosure and the environment, equalizing any pressure difference. They also prevent condensation 
            from forming inside the enclosure. In addition to equalizing pressure, the vents are resistant to water, dust, sand, and other 
            materials that can damage the device. We offer pressure equalization vents in two different structures.
            </p>
            <br></br>
            <p className="article-text justify">
            Adhesive pressure equalization membranes are particularly suitable for thin-walled enclosures or tight spaces. These durable vents 
            are easy to integrate and install, for example in portable device casings or mobile devices. Our selection includes the most common 
            round standard sizes, but we can also provide vents made to the dimensions required by the customer. Screw-on pressure equalization 
            membranes offer versatile options for demanding conditions. Plastic-framed vents are a cost-effective and simple solution for spaces 
            where there is more room. Metal-framed vents are suitable for extreme conditions, with a temperature resistance of +150 °C and a shock-resistant frame structure.
            </p>
            <div className="flex-container">
              <div className="flex-container narrow">
                <img src={ProductImage_2} alt="Image" className="article-image" />
                <br></br>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <h1 className="std-subheader">Pressure Vents</h1>
            <br></br>
            <p className="article-text justify">
            Pressure vents are essential components that protect electronic devices from the damaging effects of pressure fluctuations and premature 
            failure. Our pressure vents feature a hydrophobic ePTFE membrane that allows air to flow freely in and out of the enclosure, while effectively 
            blocking water and moisture. This ensures that the internal components of the device are protected from moisture damage, and prolongs the 
            operational life of the device. These vents are specially designed for use in rugged electronic devices, where strict IP ratings and long-term durability are critical requirements. 
            </p>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <PressureVentProductTable></PressureVentProductTable>
              </div>
            </div>
            <br></br>
            <h1 className="std-subheader">Acustic Membranes</h1>
            <br></br>
            <p className="article-text justify">
            Acoustic membranes are engineered to provide a balance between pressure equalization and sound transmission. These membranes are designed with a specific acoustic 
            impedance that allows for minimal impact on sound quality, both in terms of sound recording and sound emission. They are commonly used in devices such as speakers 
            and microphones where high-quality sound is a critical factor. The specially designed membrane allows for optimal airflow while blocking unwanted 
            moisture and debris from entering the device.
            </p>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <AcusticVentProductTable></AcusticVentProductTable>
              </div>
            </div>
            <br></br>
            <h1 className="std-subheader">Acustic Membranes for Dust Protection</h1>
            <br></br>
            <p className="article-text justify">
            Acoustic dust filters are a specialized form of pressure vents that are designed to protect electronic devices from dust and other particles while also 
            maintaining high acoustic transmission quality. 
            </p>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <AcusticDustVentProductTable></AcusticDustVentProductTable>
              </div>
            </div>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <img src={ProductImage_1} alt="Image" className="article-image" />
                <br></br>
              </div>
            </div>
            <br></br>
            <h1 className="std-subheader">Hard Vents With Plastic Housing</h1>
            <br></br>
            <p className="article-text justify">
            Pressure vents with plastic housing are components designed for venting electronic enclosures in a cost-effective and lightweight way. 
            The plastic housing offers a durable and reliable solution for a wide range of applications, where corrosive mediums and extreme temperatures 
            are not present. These vents are easy to install and integrate, and are available in a variety of standard sizes or can be custom-made to 
            fit specific requirements. They are suitable for various industries, such as consumer electronics, machinery, and industrial control systems. 
            The plastic housing provides a cost-effective and lightweight option for venting electronic enclosures, while maintaining reliable performance and durability.
            </p>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <PlasticVentProductTable></PlasticVentProductTable>
              </div>
            </div>
            <br></br>
            <p className="article-text justify">
            Pressure vents with metal housing are designed to provide reliable and durable protection for electronic devices and equipment exposed to harsh environments. 
            These components are constructed with high-quality metal housing, which can withstand high temperatures and corrosive mediums, making them suitable for use in a 
            wide range of industrial and outdoor applications. Additionally, these pressure vents feature a hydrophobic ePTFE membrane that allows air to pass through while 
            blocking water and moisture, ensuring optimal performance and longevity. Whether you need to ventilate a control cabinet, a pump station, or an outdoor enclosure, 
            pressure vents with metal housing are the perfect solution for protecting your valuable equipment from the elements.
            </p>
            <br></br>
            <h1 className="std-subheader">Hard Vents With Metal Housing</h1>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <MetalVentProductTable></MetalVentProductTable>
              </div>
            </div>
            <div>
              <br></br>
              <br></br>
              <p className="article-text">
              Are you looking for a reliable solution to protect your electronic devices from the effects of pressure changes and moisture? Contact us now to learn more about our 
              products and how we can help you solve your pressure venting needs.
              </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function PressureVentProductTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Koko</th>
          <th className="table-header">Väri</th>
          <th className="table-header">IP-Luokka</th>
          <th className="table-header">Käyttölämpötila</th>
          <th className="table-header">Toimitusaika</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {pressure_vent_products.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.size}</th>
            <th className="table-cell left">{material.color}</th>
            <th className="table-cell center">{material.ipclass}</th>
            <th className="table-cell center">{material.temprange}</th>
            <th className="table-cell left">{material.time}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function AcusticVentProductTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Koko</th>
          <th className="table-header">Väri</th>
          <th className="table-header">IP-Luokka</th>
          <th className="table-header">Käyttölämpötila</th>
          <th className="table-header">Toimitusaika</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {acustic_vent_products.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.size}</th>
            <th className="table-cell left">{material.color}</th>
            <th className="table-cell center">{material.ipclass}</th>
            <th className="table-cell center">{material.temprange}</th>
            <th className="table-cell left">{material.time}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function AcusticDustVentProductTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Koko</th>
          <th className="table-header">Väri</th>
          <th className="table-header">IP-Luokka</th>
          <th className="table-header">Käyttölämpötila</th>
          <th className="table-header">Toimitusaika</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {acustic_dust_vent_products.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.size}</th>
            <th className="table-cell left">{material.color}</th>
            <th className="table-cell center">{material.ipclass}</th>
            <th className="table-cell center">{material.temprange}</th>
            <th className="table-cell left">{material.time}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function PlasticVentProductTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Koko</th>
          <th className="table-header">Rungon materiaali</th>
          <th className="table-header">IP-Luokka</th>
          <th className="table-header">Käyttölämpötila</th>
          <th className="table-header">Toimitusaika</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {plastic_vent_products.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.size}</th>
            <th className="table-cell left">{material.material}</th>
            <th className="table-cell center">{material.ipclass}</th>
            <th className="table-cell center">{material.temprange}</th>
            <th className="table-cell left">{material.time}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function MetalVentProductTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Koko</th>
          <th className="table-header">Rungon materiaali</th>
          <th className="table-header">IP-Luokka</th>
          <th className="table-header">Käyttölämpötila</th>
          <th className="table-header">Toimitusaika</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {metal_vent_products.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.size}</th>
            <th className="table-cell left">{material.material}</th>
            <th className="table-cell center">{material.ipclass}</th>
            <th className="table-cell center">{material.temprange}</th>
            <th className="table-cell left">{material.time}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}




