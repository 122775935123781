
import { CallToAction } from "../components/common-components";

import {
  Link as RouterLink
} from "react-router-dom";


const bannerImage = "/images/material_image.jpg"

const exampleImage = "/images/example_image.png"

const materials = [
  { image: "/images/two_sided_tape_close.jpg", header: "Adhesives", text: "Katso kaksipuoleisia teippejä", url: "/two-sided-tapes" },
  { image: "/images/EPDM_samples.png", header: "Sealing & Shock Absorbtion", text: "Katso kaksipuoleisia teippejä", url: "/gaskets" },
  { image: "/images/Thermal tape sample.png", header: "Thermal Interfacing", text: "Katso kaksipuoleisia teippejä", url: "/tim-materials" },
  { image: "/images/acustic_vent_1.jpeg", header: "Pressure Equalization", text: "Katso kaksipuoleisia teippejä", url: "/acustic-materials" },
  { image: "/images/EMI components.png", header: "EMI Shielding", text: "Tutustu erikoisiin ratkaisuihin", url: "/emi-materials" },
  // { image: "/images/screen_cover_1.png", header: "Suojakalvot", text: "Tutustu erikoisiin ratkaisuihin", url: "/cover-materials" },
]

export function MaterialsListPage() {

  return (
    <div>
      <ImageBanner img={""} header="Materiaalit"></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Materials</h1>
            <p className="article-text justify">
              We deliver made to shape products to meet various industrial needs quickly and cost-effectively, directly from our stock.
              Our material inventory includes double-sided and self-adhesive tapes, acrylic foam tapes, sealing materials, and thermal
              conductive materials. In addition to our in-stock products, we are happy to source specialty materials tailored to your
              specific project requirements, such as protective films, filter materials, and pressure equalization membranes.
            </p>
            <br></br>
            <div className="flex-container">
              <div className="flex-container narrow">
                <img src={exampleImage} alt="Image" className="article-image" />
                <br></br>
              </div>
            </div>
            <br></br>
            <p className="article-text">
              Explore our range of materials and their benefits below.
            </p>
            <div>
              <MaterialGrid></MaterialGrid>
            </div>
            <div>
              <br></br>
              <p className="article-text">
                Take the first step towards finding the solution you need by reaching out to us today.
                Our team is happy to tell you more about our products and how they can benefit you.
              </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const { img, header } = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
      </div>
    </div>
  )
}

export function MaterialGrid(props) {

  return (
    <div className="grid-container">
      {materials.map((material) => (
        <RouterLink to={material.url}>
          <Card
            image={material.image}
            header={material.header}
            text={material.text}
          >
          </Card>
        </RouterLink>
      ))}
    </div>
  )
}


export function Card(props) {

  const { image, header, text } = props

  return (
    <div className="card">
      <div className="card-banner">
        <img src={image} className="card-image white" alt="no image..."></img>
      </div>
      <div className="flex-container center-text">
        <h1 className="card-header">{header}</h1>
      </div>
      {/* 
      <div>
        <p className="card-text">{text}</p>
      </div>
      */}
    </div>
  )
}