import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './Theme/theme.css';

// IMPORT PAGES
import Header from './components/Header';
import { Footer } from './components/Footer';
import Home from './pages/Home';
import {AboutPage} from './pages/About';
import {ServicesPage} from './pages/Services';
import {ProductsPage} from './pages/Products';
import {MaterialsListPage} from './pages/Materials';
import { OrderPage } from './pages/Order';

// IMPORT MATERIAL PAGES
import { TwoSidedTapesPage } from './pages/material_pages/two_sided_tapes';
import { TimMaterialsPage } from './pages/material_pages/tim_materials';
import { VhbMaterialPage } from './pages/material_pages/vhb_materials';
import { TapesWithoutPackingPage } from './pages/material_pages/tapes_without_backing';
import { GasketMaterialsPage } from './pages/material_pages/gasket_materials';
import { SpecialMaterialsPage } from './pages/material_pages/special_materials';
import { AcusticMaterialsPage } from './pages/material_pages/acustic-materials';
import { EmiMaterialsPage } from './pages/material_pages/emi_materials';
import { PlasticMaterialsPage } from './pages/material_pages/plastic-materials';
import { CoverMaterialsPage } from './pages/material_pages/cover-materials';

// IMPORT PRODUCT PAGES
import { VentsPage } from './pages/product_pages/Vents_products';


// IMPORT FUNCTIONAL SCRIPTS
import ScrollToTop from './components/ScrollToTop';

// IMPORT ROUTER
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// POPULATE HEADER
const sections = [
  { title: 'Home', url: '/home' },
  { title: 'Products', url: '/products' },
  { title: 'Materials', url: '/materials' },
  { title: 'Services', url: '/services' },
  { title: 'About Us', url: '/about' },
];

// POPULATE FOOTER
const footerMidSection = [
  { title: 'Home', url: '/home' },
  { title: 'Products', url: '/products' },
  { title: 'Materials', url: '/materials' },
  { title: 'Services', url: '/services' },
  { title: 'About Us', url: '/about' },
  // { title: 'Käyttöehdot', url: "/information" },
]

const footerLeftSections = [
  /*{ title: 'Käyttöehdot', url: "/information" },
  { title: 'Evästeet', url: "/information" },
  { title: 'Tietosuoja', url: "/information" },*/
]


function App() {

  return (
    <Router>
      <ScrollToTop />
      <div className="root-container">
        <Header sections={sections} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/services">
            <ServicesPage />
          </Route>
          <Route path="/products">
            <ProductsPage />
          </Route>
          <Route path="/materials">
            <MaterialsListPage />
          </Route>
          <Route path="/two-sided-tapes">
            <TwoSidedTapesPage />
          </Route>
          <Route path="/tapes-without-backing">
            <TapesWithoutPackingPage />
          </Route>
          <Route path="/tim-materials">
            <TimMaterialsPage />
          </Route>
          <Route path="/special-materials">
            <SpecialMaterialsPage />
          </Route>
          <Route path="/vhb-tapes">
            <VhbMaterialPage />
          </Route>
          <Route path="/vents_products">
            <VentsPage />
          </Route>
          <Route path="/emi-materials">
            <EmiMaterialsPage />
          </Route>
          <Route path="/gaskets">
            <GasketMaterialsPage />
          </Route>
          <Route path="/acustic-materials">
            <AcusticMaterialsPage />
          </Route>
          <Route path="/plastic-materials">
            <PlasticMaterialsPage />
          </Route>
          <Route path="/cover-materials">
            <CoverMaterialsPage />
          </Route>
          <Route path="/order-information">
            <OrderPage />
          </Route>
        </Switch>
        <div className="vertical-grow"></div>
        <Footer footerMidSection={footerMidSection} footerLeftSections={footerLeftSections} />
      </div>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    {/*<ThemeProvider theme={appTheme}>*/}
    <App />
    {/*</ThemeProvider>*/}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
