
import clock from "../icons/clock.png";
/*import quality from "../icons/quality.png";
import simple from "../icons/simple.png";*/
import offer from "../icons/offer.png";
import handshake from "../icons/handshake.png";

import {
  Link as RouterLink
} from "react-router-dom";

export function WhyFromUs() {

  return (
    <div className="flex-container">
      <div className="grid-container">
        <RouterLink
        to='/services'
        >
        <Card
          img={clock}
          header="Quick Prototyping"
          text="Take advantage of our extensive material inventory and experience the fast prototyping times, with most orders fulfilled within just a few days of placing your order"
        ></Card>
        </RouterLink>
        <RouterLink
        to='/services'
        >
        <Card
          img={handshake}
          header="Flexibility"
          text="Together with our extensive network of suppliers, we are able to quickly obtain the specialty materials you need. Let us help you get what you need for your next project, quickly and efficiently"
        ></Card>
        </RouterLink>
        <RouterLink
        to='/services'
        >
        <Card
          img={offer}
          header="Efficiency"
          text="Cutting-edge production technologies and extensive network of partners, we are able to deliver both small and large production runs efficiently and cost-effectively"
        ></Card>
        </RouterLink>
      </div>
    </div>
  )
}

export function Card(props) {

  const { img, header, text } = props


  return (
    <div className="card tall">
      <div className="card-banner">
        <div className="flex-container">
          <img src={img} className="card-icon" alt="no image..."></img>
        </div>
      </div>
      <div className="flex-container">
        <h1 className="card-header">{header}</h1>
      </div>
      <div>
        <p className="card-text">{text}</p>
      </div>
    </div>
  )
}