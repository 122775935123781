import { CallToAction } from "../../components/common-components";


const bannerImage = "/images/EMI_banner_image.jpg"
const image1 = "/images/EMI components.png"
const image2 = "/images/EMI foam.png"
const image3 = "/images/conductive silicone seals product image.png"
const image4 = "/images/plexiglass insulation components.png"

const materials = [
  { product: '3M 9088', description: 'Yleisteippi useimmille pinnoille, hyvä pitovoima', thickness: '200 µm', glue: 'Akryyli 375', temprange: '150-90°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000410?locale=en-US' },
  { product: '3M 92015', description: 'Yleisteippi sileille pinnoille, hyvä leikkauslujuus', thickness: '150 µm', glue: 'Akryyli 200MP', temprange: '149-93°C', application: 'Metallit ja korkean pinta-energina muovit', datasheet: 'https://3m.citrination.com/pif/000282?locale=en-US' },
  { product: '3M 9495LE', description: 'Erinomainen pitovoima useimille pinnoille', thickness: '170 µm', glue: 'Akryyli 300LSE', temprange: '204-149°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/001344?locale=en-US' },
  { product: '3M 9490LE', description: 'Erinomainen pitovoima useimille pinnoille', thickness: '170 µm', glue: 'Akryyli 300LSE', temprange: '204-149°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://multimedia.3m.com/mws/media/771682O/3mtm-double-coated-tape-9490le.pdf' },
  { product: '3M 9629PC', description: 'Todella nopea tartunta, korkea pitovoima ohueksi teipiksi', thickness: '100 µm', glue: 'Akryyli 360', temprange: '350-200°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000421?locale=en-US' },
];

export function EmiMaterialsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">EMI-Shielding</h1>
            <br></br>
            <h1 className="std-subheader">EMI Noise Suppression Material</h1>
            <br></br>
            <p className="article-text">
              EMI noise suppression sheet comprises of magnetic powder impregnated to flexible polymer carrier material,
              sandwiched between protective PET layer and an adhesive for mounting components. The material offers excellent
              noise suppression characteristics for a wide frequency range while being thin and flexible. For these reasons, the
              material is well suited for manufacturing die cut EMI shielding components for modern space restricted electronic devices.
            </p>
            <br></br>
            <div className="flex-container">
              <img src={image1} alt="Image" className="article-image m" />
            </div>
            <div>
              <br></br>
              <br></br>
              <h1 className="std-subheader">Electrically Conductive Foam</h1>
              <br></br>
              <p className="article-text">
                Electrically conductive foam stands for cellular polyurethane foam, which has been
                metalized with nickel or copper. The metallization allows the foam to be electrically
                conductive in all directions. The die cut components are typically used for shielding and
                grounding of individual components or assemblies. with the added possibility of utilizing the
                foam as a seal against dust or water. We provide die cut foam components, from wide range of materials,
                which can be combined with adhesive layers and installation foils for flexible component design and fast assembly.
              </p>
              <br></br>
              <div className="flex-container">
                <img src={image2} alt="Image" className="article-image m" />
              </div>
              <br></br>
              <br></br>
              <h1 className="std-subheader">Electrically Conductive Silicone</h1>
              <br></br>
              <p className="article-text">
                Electrically conductive silicone rubber is a carbon filled silicone material that will act as a
                low amperage conductor and provides protection against electrostatic discharge. Electrically conductive
                silicone rubber is typically used for EMI applications when there is also need for ingress protection class.
                Gaskets can be die-cut mechanically from large sheets or molded directly to correct shape for cost efficiency in large quantities.
              </p>
              <br></br>
              <div className="flex-container">
                <img src={image3} alt="Image" className="article-image m" />
              </div>
              <br></br>
              <br></br>
              <h1 className="std-subheader">Electrical Insulation</h1>
              <br></br>
              <p className="article-text">
              Insulators are designed to prohibit the flow of electric current along unintended paths within 
              and beyond the electrical equipment or device. Insulators preserve the designed function of the electrical 
              component while protecting adjacent components, devices, and users. Custom shaped electrical insulators such as plastic 
              films and rubbers are thin, flexible, and easy way to obtain the required insulation level within the devices.
              </p>
              <br></br>
              <div className="flex-container">
                <img src={image4} alt="Image" className="article-image m" />
              </div>
              <br></br>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}


export function MaterialTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Liima</th>
          <th className="table-header">Materiaalit</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {materials.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell center">{material.thickness}</th>
            <th className="table-cell center">{material.glue}</th>
            <th className="table-cell left">{material.application}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}