import {
  Link as RouterLink
} from "react-router-dom";

export function CallToAction() {
  return (
    <div className="flex-container">
      <RouterLink to='/about'>
        <button className="std-button highlight cat" style={{ margin: "24px" }}>Contact Us</button>
      </RouterLink>
    </div>
  )
}