
import { CallToAction } from "../components/common-components";

import {
  Link as RouterLink
} from "react-router-dom";

//const bannerImage = "/images/poron_test.png"
const bannerImage = "/images/material_image.jpg"


const materials = [
  { image: "/images/plastic_vent_1.png", header: "Pressure Vents", text: "Tutustu valikoimaamme", url: "/vents_products" },
  { image: "/images/poron_samples.png", header: "Made to Shape Products", text: "Tutustu materiaaleihin", url: "/materials" },
]

export function ProductsPage() {

  return (
    <div>
      <ImageBanner img={""} header="Tuotteet"></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Solutions</h1>
            <p className="article-text justify">
            Our selection includes both custom-fit, tailored solutions and standard components commonly used 
            in the industry. We deliver cut-to-shape products efficiently and cost-effectively directly from our 
            stock for various industrial needs. Our material inventory includes adhesive tapes, acrylic foam tapes, 
            sealing materials, and thermal conductivity materials. In addition to our stock products, we are also happy 
            to acquire any specialized materials required for your project such as protective films, filter materials, and pressure equalization films.
            </p>
            <br></br>
            <p className="article-text">
            Explore our offerings in more detail below and see how we can help you find the perfect solution for your needs.
            </p>
            <div>
              <MaterialGrid></MaterialGrid>
            </div>
            <div>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const { img, header } = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
      </div>
    </div>
  )
}

export function MaterialGrid(props) {

  return (
    <div className="grid-container">
      {materials.map((material) => (
        <RouterLink to={material.url}>
          <Card
            image={material.image}
            header={material.header}
            text={material.text}
          >
          </Card>
        </RouterLink>
      ))}
    </div>
  )
}


export function Card(props) {

  const { image, header, text } = props

  return (
    <div className="card">
      <div className="card-banner">
        <img src={image} className="card-image white" alt="no image..."></img>
      </div>
      <div className="flex-container center-text">
        <h1 className="card-header">{header}</h1>
      </div>
      {/* 
      <div>
        <p className="card-text">{text}</p>
      </div>
      */}
    </div>
  )
}