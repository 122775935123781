import {
  Link as RouterLink
} from "react-router-dom";

// const bannerImage = "/images/poron_test.png"
const bannerImage = "/images/Kuva_1.jpg"

export function AboutPage() {

  return (
    <div>
      <ImageBanner></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">About Us</h1>
            <p className="article-text">
              Prototape Oy is a company founded in 2021 in Kotka, specializing in the prototyping and mass production of die-cut tapes, seals, and thermal management components.
              Our primary goal is to help our customers achieve the benefits of die-cut products by offering services from material selection to component design, from fast prototyping
              to cost-effective procurement of large quantities.
            </p>
            <br></br>
            <p className="article-text">
              Don't hesitate to reach out to us and experience the benefits of our services for yourself. Our team will work with you to find the best solutions for your specific
              needs, and help bring your products to the next level. Contact us today to learn more about how we can help you achieve your goals.
            </p>
            <br></br>
            {/*
            <p className="article-text">
              Call to action tähän.
            </p>
            */}
            <div>
              <About></About>
            </div>
            <br></br>
            {/*<div>
              <PaymentInformation></PaymentInformation>
            </div>
            <br></br>
            <br></br>*/}
            {/*
            <p className="article-text">
              Tänne voi laittaa sen kartan mutta siihen tarvitaan Google tili.
            </p>
            */}
          </div>
        </div>
      </div>
    </div>
  )
}

export function Article() {

  return (
    <div>
    </div>
  )
}

export function ImageBanner() {
  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}

export function Header() {

}

export function About() {
  return (
    <div>
      <div className="vertical-spacer"></div>
      <h1 className="std-subheader">Prototape Oy</h1>
      <h1 className="std-text">Postal Address: Mäensyrjä 2 B6, 48310 Kotka</h1>
      <h1 className="std-text">Phone: <a className="std-hyperlink" href="tel:+358 45 7833 7096">+358 45 7833 7096</a></h1>
      <h1 className="std-text">Email: <a className="std-hyperlink" href="mailto:info@prototape.fi">info@prototape.fi</a></h1>
      <div className="vertical-spacer"></div>
    </div>
  );
}

export function PaymentInformation() {
  return (
    <div>
      <div className="vertical-spacer"></div>
      <h1 className="std-subheader">Billing information</h1>
      <h1 className="std-text">Verkkolaskutusosoite: 003732224631</h1>
      <h1 className="std-text">Välittäjätunnus: 003723327487</h1>
      <h1 className="std-text">Verkkolaskutusvälittäjä: APIX Messaging Oy</h1>
      <div className="vertical-spacer"></div>
    </div>
  );
}

