import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/material_image.jpg"
const image1 = "/images/vhb_samples.png"
const image2 = "/images/tape without carrier.png"
const image3 = "/images/two_sided_tapes_samples.png"

const materials = [
  { product: '3M 9088', description: 'Yleisteippi useimmille pinnoille, hyvä pitovoima', thickness: '200 µm', glue: 'Akryyli 375', temprange: '150-90°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000410?locale=en-US' },
  { product: '3M 92015', description: 'Yleisteippi sileille pinnoille, hyvä leikkauslujuus', thickness: '150 µm', glue: 'Akryyli 200MP', temprange: '149-93°C', application: 'Metallit ja korkean pinta-energina muovit', datasheet: 'https://3m.citrination.com/pif/000282?locale=en-US' },
  { product: '3M 9495LE', description: 'Erinomainen pitovoima useimille pinnoille', thickness: '170 µm', glue: 'Akryyli 300LSE', temprange: '204-149°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/001344?locale=en-US' },
  { product: '3M 9490LE', description: 'Erinomainen pitovoima useimille pinnoille', thickness: '170 µm', glue: 'Akryyli 300LSE', temprange: '204-149°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://multimedia.3m.com/mws/media/771682O/3mtm-double-coated-tape-9490le.pdf' },
  { product: '3M 9629PC', description: 'Todella nopea tartunta, korkea pitovoima ohueksi teipiksi', thickness: '100 µm', glue: 'Akryyli 360', temprange: '350-200°C', application: 'Metallit ja suurin osa muoveista', datasheet: 'https://3m.citrination.com/pif/000421?locale=en-US' },
];

export function TwoSidedTapesPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Adhesives</h1>
            <br></br>
            <h1 className="std-subheader">Double Sided Tapes</h1>
            <br></br>
            <p className="article-text">
              Double-sided tapes provide a versatile and cost-effective solution for bonding a wide range of materials, including metals,
              high surface energy plastics, and glass. These tapes are easy to die-cut, laminate to other materials, and customize with
              printing, making them suitable for a vast array of applications. Whether you're looking for an adhesive for industrial or consumer-facing projects
            </p>
            <br></br>
            <div className="flex-container">
              <img src={image3} alt="Image" className="article-image m" />
            </div>
            <div>
              <br></br>
              <br></br>
              <h1 className="std-subheader">Transfer Tapes Without Carrier</h1>
              <br></br>
              <p className="article-text">
                Transfer tapes without carrier are a versatile and efficient solution for bonding thin and delicate materials.
                These tapes are composed of a single adhesive layer, making them perfect for use in assemblies where a carrier would
                be too thick or cumbersome.
              </p>
              <br></br>
              <p className="article-text">
                The absence of a carrier also means that transfer tapes are highly conformable and can easily
                adhere to irregular surfaces. With a wide range of industrial applications, including electronics, signage, graphics, and
                more, transfer tapes without carrier are an essential tool for any business looking for a cost-effective and reliable bonding solution.
              </p>
              <br></br>
              <div className="flex-container">
                <img src={image2} alt="Image" className="article-image m" />
              </div>
              <br></br>

              <br></br>
              <h1 className="std-subheader">Foam Tapes</h1>
              <br></br>
              <p className="article-text">
                Foam tapes are versatile and reliable adhesives that can be used for a wide range of applications.
                They are made from a flexible foam material that is easy to die cut and shape to fit any surface.
                They provide a cost-effective solution for bonding, insulation, and cushioning needs. Foam tapes are
                ideal for use in automotive, construction, and HVAC industries, as they can withstand extreme temperatures
                and weather conditions. They are also suitable for use in electronics and appliances, as they provide excellent
                vibration and noise dampening properties.
              </p>
              <br></br>
              <div className="flex-container">
                <img src={image1} alt="Image" className="article-image m" />
              </div>
              <br></br>
              <p className="article-text">
                Foam tapes are available in a variety of densities and thicknesses,
                making them suitable for both light and heavy-duty applications. They offer excellent adhesion and durability,
                making them a cost-effective and long-lasting solution for many industries.
              </p>
              <br></br>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}


export function MaterialTable(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Kuvaus</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Liima</th>
          <th className="table-header">Materiaalit</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>
        {materials.map((material) => (
          <tr className="table-row">
            <th className="table-cell left">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell center">{material.thickness}</th>
            <th className="table-cell center">{material.glue}</th>
            <th className="table-cell left">{material.application}</th>
            {/*<th className="table-cell center"><a target="_blank" href={material.datasheet}>Avaa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}