import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/two_sided_tape_far.jpg"

export function SpecialMaterialsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Erikoisratkaisut</h1>
            <p className="article-text">
              Normaalien teippi- ja tiivistemateriaalien lisäksi tarjoamme myös ratkaisuja erikoisempiin
              käyttökohteisiin. Tällä sivulla on esitelty vain osa mahdollisuuksista joita tarjoamme.
            </p>
            <br></br>
            <div>
              <h1 className="std-subheader">Hydro-/oleofobiset suodatinmateriaalit:</h1>
            </div>
            <p className="article-text">
              Kykenemme leikkaamaan myös erityyppisiä suodatinmateriaaleija esimerkiksi valmistajilta Pall / Porex.

            </p>
            <br></br>
            <div>
              <h1 className="std-subheader">PE / PET / BoPET kalvot:</h1>
            </div>
            <p className="article-text">
              Leikkaamme myös muovikalvoja esimerkiksi valmistuksenaikasiksi näytönsuojiksi sekä sähköisiksi eristeiksi.
            </p>
            <div>
              <br></br>
              <br></br>
              <p className="article-text">
                Pidämme yleisimmin käytettyjä materiaaleja aina varastossa, erikoisemmissa tarpeissa
                pystymme hankkimaan nopeasti tarvitsemasi materiaalin.
              </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}
