import { CallToAction } from "../../components/common-components";

const bannerImage = "/images/Kuva_1.jpg"

const image1 = "/images/tim-samples.png"
const image2 = "/images/Thermal tape sample.png"
const image3 = "/images/grafite_samples.png"
const image4 = "/images/phase change materials.png"

// 3M Thermally Conductive Interface Pads
const materials_1 = [
  { product: '5550H', description: 'Erittäin pehmeä, korkea lämmönjohtavuus', thickness: '0.5 - 1.5 mm', HValue: '3.0 W/m-K', hardness: '30', datasheet: 'https://multimedia.3m.com/mws/media/1648105O/3m-thermally-conductive-acrylic-interface-pad-5550h.pdf' },
  { product: '5570N', description: 'Pehmeä, matala lämmönjohtavuus', thickness: '0.5 - 2.0 mm', HValue: '1.3 W/m-K', hardness: '50', datasheet: 'https://multimedia.3m.com/mws/media/1243168O/3m-thermally-conductive-acrylic-interface-pad-5570n.pdf' },
  { product: '5571', description: 'Helposti käsiteltävä, hyvä lämmönjohtavuus', thickness: '0.75 - 2.0 mm', HValue: '2.0 W/m-K', hardness: '70', datasheet: 'https://multimedia.3m.com/mws/media/769338O/3m-thermally-conductive-acrylic-interface-pad-5571.pdf' },
  { product: '5571N', description: 'Helposti käsiteltävä, hyvä lämmönjohtavuus', thickness: '0.5 mm', HValue: '2.0 W/m-K', hardness: '70', datasheet: 'https://multimedia.3m.com/mws/media/769338O/3m-thermally-conductive-acrylic-interface-pad-5571.pdf' },
  { product: '5578H', description: 'Helposti käsiteltävä, korkea lämmönjohtavuus', thickness: '0.5 - 2.0 mm', HValue: '3.5 W/m-K', hardness: '70', datasheet: 'https://multimedia.3m.com/mws/media/944580O/3m-thermally-conductive-acrylic-interface-pad-5578h.pdf' },
  { product: '5589H', description: 'Pehmeä, hyvä lämmönjohtavuus', thickness: '1.0 - 1.5 mm', HValue: '2.0 W/m-K', hardness: '50', datasheet: 'https://multimedia.3m.com/mws/media/527585O/3m-thermally-conductive-acrylic-interface-pad-5589h.pdf' },
  { product: '5590H', description: 'Helposti käsiteltävä, korkea lämmönjohtavuus', thickness: '0.5 - 1.5 mm', HValue: '2.8 W/m-K', hardness: '70', datasheet: 'https://multimedia.3m.com/mws/media/920112O/3m-thermally-conductive-acrylic-interface-pad-5590h.pdf' },
  { product: '5590PI', description: 'Erittäin ohut, korkea lämmönjohtavuus', thickness: '0.2 mm', HValue: '3.0 W/m-K', hardness: '60', datasheet: 'https://multimedia.3m.com/mws/media/1266765O/3m-thermally-conductive-acrylic-interface-pad-5590pi.pdf' },
];

// Sheen lämmönsiirtomateriaalit
const materials_2 = [
  { product: 'AF100', description: 'Pehmeä, matala lämmönjohtavuus', thickness: '0.25 - 5.0 mm', HValue: '1.0 W/m-K', hardness: '45 - 90', datasheet: 'https://www.usheenthermal.com/non-silicone-thermal-pad/non-siliconegap-pad.html' },
  { product: 'AF150', description: 'Pehmeä, matala lämmönjohtavuus', thickness: '0.25 - 5.0 mm', HValue: '1.5 W/m-K', hardness: '50 - 90', datasheet: 'https://www.usheenthermal.com/non-silicone-thermal-pad/non-siliconeheat-transfer-pad.html' },
  { product: 'AF300', description: 'Pehmeä, hyvä lämmönjohtavuus', thickness: '0.5 - 5.0 mm', HValue: '2.0 W/m-K', hardness: '40 - 90', datasheet: 'https://www.usheenthermal.com/non-silicone-thermal-pad/acryl-silicone-thermal-pad.html' },
  { product: 'AF500', description: 'Pehmeä, korkea lämmönjohtavuus', thickness: '0.5 - 5.0 mm', HValue: '3.0 W/m-K', hardness: '45 - 90', datasheet: 'https://www.usheenthermal.com/non-silicone-thermal-pad/acryl-thermal-interface-sheet.html' },
  { product: 'AF500G', description: 'Pehmeä, korkea lämmönjohtavuus', thickness: '0.5 - 5.0 mm', HValue: '3.5 W/m-K', hardness: '45 - 90', datasheet: 'https://www.usheenthermal.com/non-silicone-thermal-pad/acryl-thermal-silicone-pad.html' },
  { product: 'AF600', description: 'Helposti käsiteltävä, erittäin korkea lämmönjohtavuus', thickness: '0.5 - 5.0 mm', HValue: '5.0 W/m-K', hardness: '70 - 90', datasheet: 'https://www.sheenthermal.com/article-show-7-120.html' },
  { product: 'AF600G', description: 'Helposti käsiteltävä, erittäin korkea lämmönjohtavuus', thickness: '0.5 - 5.0 mm', HValue: '6.0 W/m-K', hardness: '70 - 90', datasheet: 'https://www.sheenthermal.com/article-show-7-123.html' },
  { product: 'AF800', description: 'Helposti käsiteltävä, erittäin korkea lämmönjohtavuus', thickness: '0.5 - 5.0 mm', HValue: '8.0 W/m-K', hardness: '70 - 90', datasheet: 'https://www.sheenthermal.com/article-show-7-124.html' }
];

// 3M Thermally COnductive Tapes
const materials_3 = [
  { product: '8805', description: 'Ohut, hyvä mekaaninen kestävyys', thickness: '0.125 mm', HValue: '0.6 W/m-K', hardness: 'Grafiitti', datasheet: 'https://www.3m.com/3M/en_US/p/d/b00017502/' },
  { product: '8810', description: 'Ohut, hyvä mekaaninen kestävyys', thickness: '0.25 mm', HValue: '0.6 W/m-K', hardness: 'Grafiitti', datasheet: 'https://www.3m.com/3M/en_US/p/d/b00051394/' },
  { product: '8815', description: 'Hyvä mekaaninen kestävyys, erinomainen iskunkestävyys', thickness: '0.375 mm', HValue: '0.6 W/m-K', hardness: 'Grafiitti', datasheet: 'https://www.3m.com/3M/en_US/p/d/b10091765/' },
  { product: '8820', description: 'Hyvä mekaaninen kestävyys, erinomainen iskunkestävyys', thickness: '0.5 mm', HValue: '0.6 W/m-K', hardness: 'Grafiitti', datasheet: 'https://www.3m.com/3M/en_US/p/d/b40044799/' },
  { product: '8926', description: 'Korkea lämmönjohtavuus, hyvä tartunta myös Al / SS', thickness: '0.2 - 0.5 mm', HValue: '1.5 W/m-K', hardness: 'Grafiitti', datasheet: 'https://multimedia.3m.com/mws/media/1817070O/adhensive-tds-3m-thermally-conductive-interface-tape-8926-series.pdf' },
];

export function TimMaterialsPage() {

  return (
    <div>
      <ImageBanner img={""}></ImageBanner>
      <div className="flex-container">
        <div className="flex-container wide">
          <div className="block-container">
            <h1 className="std-header">Thermal Interfacing Materials</h1>
            <br></br>
            <div>
              <h1 className="std-subheader">Thermal Interfacing Pads</h1>
            </div>
            <br></br>
            <p className="article-text">
              Thermally conductive pads are an essential component for effectively dissipating heat from electronic devices.
              They provide a reliable thermal interface between heat sinks and electronic devices, even when faced with uneven surfaces,
              air gaps, and rough surface textures. With their soft and conformable construction, thermally conductive pads effectively
              reduce interface resistance and provide optimal thermal control for a wide range of applications. Additionally, their
              shock-damping properties help to reduce vibration stress, ensuring the longevity of your electronic devices.
              Available in a variety of thicknesses and conductivities, our thermally conductive pads are designed to meet the specific needs of your application
            </p>
            <br></br>
            <div className="flex-container">
              <img src={image1} alt="Image" className="article-image m" />
            </div>
            <div>
              <h1 className="std-subheader">Thermal Transfer Tapes</h1>
            </div>
            <br></br>
            <p className="article-text">
              Thermal transfer tapes are a highly effective solution for heat dissipation in electronic devices.
              These tapes are designed to provide a preferential heat-transfer path between heat-generating components
              and heat sinks or other cooling devices. They are made with an advanced adhesive formula that ensures a
              strong bond between components without the need for mechanical fixturing. Thermal transfer tapes can be
              supplied without internal carrier for better thermal conductivity or with reinforcing carrier for better
              dimensional stability and handling, which makes it easy to install and use. The tapes are available in
              various thicknesses and conductivities to match the needs of different applications, which ensures optimized thermal control in every application.
            </p>
            <br></br>
            <div className="flex-container">
              <img src={image2} alt="Image" className="article-image m" />
            </div>
            <div>
              <h1 className="std-subheader">Phase Change Materials</h1>
            </div>
            <br></br>
            <p className="article-text">
              The phase change thermal interface materials combine the benefits of easy handling of
              thermal interface sheets and the excellent gap filling properties of liquid thermal pastes.
              At room temperature the material is solid, which allows for fast and clean assembly. At
              elevated temperatures, the material softens and fills any gaps between the interfaced
              components as default the material features a naturally tacky surface on both sides, for
              easy assembly. For more adhesion, the components can be specified with glue surface
              on any side. The die cut components can be supplied with pull tabs and aligning
              features for quick assembly.
            </p>
            <br></br>
            <div className="flex-container">
              <img src={image4} alt="Image" className="article-image m" />
            </div>
            <div>
              <h1 className="std-subheader">Graphite</h1>
            </div>
            <br></br>
            <p className="article-text">
              Natural graphite sheets are an efficient solution for thermal transfer as they have a very high thermal conductivity,
              making them highly effective at dissipating heat. They are also able to withstand high operating temperatures and can
              be die cut to fit specific application needs. Additionally, natural graphite is a cost-effective choice compared to other
              thermal interfacing materials, and it's made of pure carbon, meaning it will not release any gases or oils.
              The die cut natural graphite components can be furthermore complimented with adhesive surfaces on one or two sides of the component.
            </p>
            <br></br>
            <div className="flex-container">
              <img src={image3} alt="Image" className="article-image m" />
            </div>
            <div>
              <br></br>
              <br></br>
              <p className="article-text">
                Don't let heat be a barrier to your electronic devices' performance, Let us help you optimize thermal control
                in your application with our wide range of thermal interfacing materials. Contact us today to learn more
              </p>
              <br></br>
              <CallToAction></CallToAction>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ImageBanner(props) {

  const img = props

  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="image-banner-header-container">
        <h1 className="std-header"></h1>
      </div>
    </div>
  )
}


export function MaterialTable_1(props) {

  return (
    <div>
      <table className="material-table">
        <tr className="table-row">
          <th className="table-header">Tuote</th>
          <th className="table-header">Ominaisuudet</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Lämmönjohtavuus</th>
          <th className="table-header">Kovuus (Shore 00)</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>

        {materials_1.map((material) => (
          <tr className="table-row">
            <th className="table-cell">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell">{material.thickness}</th>
            <th className="table-cell">{material.HValue}</th>
            <th className="table-cell">{material.hardness}</th>
            {/*<th className="table-cell"><a target="_blank" href={material.datasheet}>Lataa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function MaterialTable_2(props) {

  return (
    <div>
      <table className="material-table">
        <tr>
          <th className="table-header">Tuote</th>
          <th className="table-header">Ominaisuudet</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Lämmönjohtavuus</th>
          <th className="table-header">Kovuus (Shore 00)</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>

        {materials_2.map((material) => (
          <tr>
            <th className="table-cell">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell">{material.thickness}</th>
            <th className="table-cell">{material.HValue}</th>
            <th className="table-cell">{material.hardness}</th>
            {/*<th className="table-cell"><a target="_blank" href={material.datasheet}>Lataa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}

export function MaterialTable_3(props) {

  return (
    <div>
      <table className="material-table">
        <tr>
          <th className="table-header">Tuote</th>
          <th className="table-header">Ominaisuudet</th>
          <th className="table-header">Paksuus</th>
          <th className="table-header">Lämmönjohtavuus</th>
          {/*<th className="table-header">Datasheet</th>*/}
        </tr>

        {materials_3.map((material) => (
          <tr>
            <th className="table-cell">{material.product}</th>
            <th className="table-cell left">{material.description}</th>
            <th className="table-cell">{material.thickness}</th>
            <th className="table-cell">{material.HValue}</th>
            {/*<th className="table-cell"><a target="_blank" href={material.datasheet}>Lataa</a></th>*/}
          </tr>
        ))}
      </table>
    </div>
  )
}