import React from 'react';
import Whatsapp from '../icons/whatsapp.png';
import Youtube from '../icons/youtube.png';
import Linkedin from '../icons/linkedin.png';
import { ContactLight } from './Contact';

import {
  Link as RouterLink
} from "react-router-dom";

const prototapelogo = "/images/logo.png"

export function Footer(props) {

  const { footerMidSection } = props;
  const { footerLeftSections } = props;

  return (
    <div className="footer-container">
      <div className="flex-container">
        <h1 className="std-text light">Prototape Oy Kotka, Finland</h1>
      </div>
      <div className="footer-grid-container">

        
        <div className="block-container xs">
          {footerLeftSections.map((section) => (
            <div className="flex-container">
              <RouterLink
                className="nav-link-footer animated-underline"
                to={section.url}
              >
                {section.title}
              </RouterLink>
            </div>
          ))}
        </div>

        <div className="block-container xs">
          {footerMidSection.map((section) => (
            <div className="flex-container">
              <RouterLink
                className="nav-link-footer animated-underline"
                to={section.url}
              >
                {section.title}
              </RouterLink>
            </div>
          ))}
        </div>

        {/*
        <div className="flex-container xs">
          <RouterLink href="https://en.wikipedia.org/wiki/Life_simulation_game">
            <img className="some-icon" src={Whatsapp}></img>
          </RouterLink>
          <RouterLink href="https://www.youtube.com/">
            <img className="some-icon" src={Youtube}></img>
          </RouterLink>
          <RouterLink href="https://www.linkedin.com/">
            <img className="some-icon" src={Linkedin}></img>
          </RouterLink>
        </div>
        */}

      </div>
      <div className="flex-container">
        <h1 className="std-copyright-text">Copyright Prototape Oy 2021-2024</h1>
      </div>
    </div>
  )
}