import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Link as RouterLink
} from "react-router-dom";

const prototapelogo = "/images/logo.png"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  text: {
    color: "white",
    marginRight: '16px',
    textDecoration: 'none',
  },
}));

export default function Header(props) {
  const { sections } = props;

  return (
    <div>
      <div className="normal-header-container">
        <NormalHeader sections={sections}></NormalHeader>
      </div>
      <div className="mobile-header-container">
        <MobileHeader sections={sections}></MobileHeader>
      </div>
    </div>
  );
};

export function NormalHeader(props) {
  const { sections } = props;
  return (
    <div className="header-container shadow">
      <div className="nav-link-container">
        <RouterLink
          to="/home"
          className="nav-link"
        >
          <div className="prototape-logo-large" style={{ backgroundImage: `url(${prototapelogo})` }}>
          </div>
        </RouterLink>
      </div>
      <div className="horizontal-spacer" />
      <div className="nav-link-container">
        {sections.map((section) => (
          <RouterLink
            to={section.url}
            className="nav-link-header animated-underline"
          >
            {section.title}
          </RouterLink>
        ))}
      </div>
    </div>
  )
}

export function MobileHeader(props) {
  const { sections } = props;
  return (
    <div className="header-container shadow">
      <div className="block-container">
        <div className="flex-container">
          <div className="flex-container">
            <RouterLink
              to="/home"
              className="nav-link"
            >
              <div className="prototape-logo-large" style={{ backgroundImage: `url(${prototapelogo})` }}>
              </div>
            </RouterLink>
          </div>
        </div>
        
        {sections.map((section) => (
          <div className="flex-container">
            <div className="flex-container">
              <RouterLink
                to={section.url}
                className="nav-link-header"
              >
                {section.title}
              </RouterLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}