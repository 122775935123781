import clock from "../icons/clock.png";
import { CallToAction } from "../components/common-components";


import {
  Link as RouterLink
} from "react-router-dom";

import { WhyFromUs } from "../components/WhyFromUs";

//const bannerImage = "/images/Pic_4.png"
// const bannerImage = "/images/Kuva_1.jpg"
const bannerImage = "/images/two_sided_tape_far.jpg"

const services = [
  { header: "NOPEA:", text: "Moderni konekantamme ja paikallinen kattava varastomme mahdollistaa nopeat toimitukset määrästä ja materiaalista riippumatta. Tuotantomenetelmämme eivät vaadi tuotekohtaisia työkaluja, mikä näkyy asiakkaalle nopeampana toimituksena ja pienempänä aloituskustannuksena.", icon: { clock } },
  { header: "KATTAVA:", text: "Kattava varastomme koostuu yleisimmin käytetyistä teipeistä ja tiiviste- sekä lämmösiirtomateriaaleistä, joilla pystymme takaamaan nopeat toimitukset protosarjoihin. Laajan toimittajaverkostomme avulla voimme tarjota myös erikoisempia materiaaleja asiakkaan vaatimusten täyttämiseksi.", icon: { clock } },
  { header: "JOUSTAVA:", text: "Perinteisen yksittäisen tilauksen laskutuksen lisäksi tarjoamme joustavasti erilaisia osto- ja palvelusopimuksia, joilla voidaan sujuvoittaa tuotekehitysprosesseja.", icon: { clock } },
]

function Home() {
  return (
    <div className="block-container">
      <HeroSection></HeroSection>
      <div>
        <div className="flex-container">
          <div className="flex-container wide">
            <div className="block-container">
              <div className="flex-container center-text">
                <h1 className="std-header">About Us</h1>
              </div>
              <div className="flex-container">
                <div className="flex-container wide" style={{ margin: "24px" }}>
                  <div>
                    <p className="article-text">
                      Prototape Oy is a Finnish company based in Kotka that specializes in die cut products.
                      We offer innovative and functional designs, efficient prototyping, and a variety of
                      cost-effective production methods for any volume of die cut and injection molded products.
                      Our goal is to provide the best made-to-shape components for our customers' products.
                    </p>
                    <br></br>
                    <p className="article-text">
                      We provide the tapes, seals, and thermal solutions you need, from prototype sets of a few tens to series production
                      of thousands of pieces. Our main production method is laser cutting, which allows for a wide range of cuttable materials,
                      minimal tool and startup costs, and complex geometries. We maintain a comprehensive material stock, allowing us to respond
                      quickly to customer needs. Additionally, we source specialized materials from our extensive network of suppliers quickly and at competitive prices.
                    </p>
                    <br></br>
                    <p className="article-text">
                      With our extensive material stock, we are able to meet the challenges of product development on a very short schedule.
                      Our stock covers materials commonly used in the electronics industry for fastening, sealing, and thermal transfer,
                      which enables us to deliver quickly and develop die-cut components for demanding products in an easy and cost-effective manner.
                    </p>
                    <br></br>
                    <p className="article-text">
                      Our material stock includes adhesive tapes, acrylic foam and double-sided tapes, sealing materials, and
                      thermal management materials. In addition to stock products, we can also provide specialized
                      products such as protective films, filter materials, and pressure equalization membranes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-container center-text">
                <h1 className="std-header">Benefits</h1>
              </div>
              <WhyFromUs></WhyFromUs>
              <div className="flex-container center-text">
                <h1 className="std-header">Contact Us</h1>
              </div>
              <CallToAction></CallToAction>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export function HeroSection() {
  return (
    <div className="image-banner shadow" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="horizontal-spacer"></div>
      <div className="hero-container dark">
        <p className="hero-section-subheader">Partner in die-cut products</p>
        <h2 className="hero-header animated-underline">Prototape Oy</h2>
        <p className="hero-text">Prototape Oy specializes in the prototyping and production of die-cut tapes, seals, and thermal transfer materials.
          In addition to high-quality production, we are able to meet the customer needs with fast turnaround times.</p>
        <div className="hero-container-action-bar">
          <RouterLink
            to="/services"
          >
            <button className="std-button highlight" style={{ marginRight: "24px" }}>Services</button>
          </RouterLink>
          <RouterLink
            to="/about"
          >
            <button className="std-button highlight">Conctact Us</button>
          </RouterLink>
        </div>
      </div>
    </div>
  );
}

export function ServiceList() {

  const icon = { clock }

  return (
    <div>
      <div>
        <h1 className="service-list-header">Valttimme:</h1>
      </div>
      {services.map((service) => (
        <div className="service-list-item-container">
          <div className="flex-container left vertical-center">
            <img src={clock} className="service-list-icon" alt="no image..."></img>
            <h1 className="std-subheader">{service.header}</h1>

          </div>
          <div>
            <p className="service-list-text">{service.text}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export function ImageBanner() {
  return (
    <div className="image-banner">
      <div className="image-banner-header-container">
        <img src={bannerImage} className="banner-image" alt="no image..."></img>
      </div>
    </div>
  )
}


export default Home;

